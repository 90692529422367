export enum AlertTypeEnum {
  ABSENCE = 'ABSENCE',
  LATENESS = 'LATENESS',
  HOMEWORK_NOT_DONE = 'HOMEWORK_NOT_DONE',
  OCCURRENCE = 'OCCURRENCE',
}

export type Alert = {
  id: number;
  contentType: string;
  isRead: boolean;
  readBy: number;
  readAt: string;
  targetId: number;
  actor: {
    id: number;
    title: string;
  };
  alertAt: string;
  comment: string;
} & (OcurrenceAlert | AbsenceAlert | LatelessAlert | HomeworkNotDone);

export type OcurrenceAlert = {
  type: AlertTypeEnum.OCCURRENCE;
  extraInfo: OccurrenceExtraInfo;
};

export type AbsenceAlert = {
  type: AlertTypeEnum.ABSENCE;
  extraInfo: AbsenceExtraInfo;
};

export type LatelessAlert = {
  type: AlertTypeEnum.LATENESS;
};

export type HomeworkNotDone = {
  type: AlertTypeEnum.HOMEWORK_NOT_DONE;
};

export type OccurrenceExtraInfo = {
  assessmentId?: number;
  authorId?: number;
  description?: string;
};

export type AbsenceExtraInfo = {
  sequence?: number;
};

export type AlertDTO = Pick<Alert, 'targetId' | 'type' | 'alertAt'> & {
  actorId: number;
};

export enum AbsenceEventTypeEnum {
  NEW_ALERT = 'NEW_ALERT',
}

export type NewAlertEvent = {
  alert: Alert;
  event: AbsenceEventTypeEnum.NEW_ALERT;
};

export type AlertEvent = NewAlertEvent;
