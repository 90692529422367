import { SimpleTag } from './Tag';

export default interface Flag extends SimpleTag {
  userAssignable: boolean;
  type: FlagTypeEnum;
}

export enum FlagTypeEnum {
  GENERAL = 'GENERAL',
  FINALIST = 'FINALIST',
}
