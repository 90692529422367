import { MainButtonProps } from '@/components/common/buttons/MainButton';
import Text from '@/components/common/dataDisplay/Text';
import SectionFilterFactory from '@/components/projects/carousel/filters/ProjectFilterFactory';
import { ProjectsSections } from '@/components/projects/carousel/ProjectCarousel';
import { ProjectFilters } from '@/data/services/projectServices';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type ProjectSectionHeaderProps = {
  section: ProjectsSections;
  filters?: ProjectFilters;
  onFilter?: (filters: ProjectFilters) => void;
  button: React.ReactElement<MainButtonProps>;
  className?: Pick<ClassNameComponent, 'title'>;
};

export default function ProjectSectionHeader({
  section,
  filters,
  onFilter,
  className,
  button,
}: ProjectSectionHeaderProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'projects.page' });

  return (
    <div className="relative flex justify-between w-full gap-2.5  items-center">
      <div className="flex gap-x-2 gap-y-1 flex-wrap items-center">
        <Text
          format="rubik-500"
          size="text-18"
          text={t(section)}
          className={twMerge('text-accent', className?.title)}
        />
        <SectionFilterFactory
          filter={filters}
          onFilter={onFilter}
          section={section}
        />
      </div>
      {button}
    </div>
  );
}
