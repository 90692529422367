import ProjectCarousel from '@/components/projects/carousel/ProjectCarousel';
import { ProjectFilters } from '@/data/services/projectServices';
import { useOutletContext } from 'react-router-dom';
import HackathonGeneral from '../../components/projects/hackathon/HackathonGeneral';
import useHackathon from '../../components/projects/hackathon/HackathonGeneral.hooks';

export default function ProjectGeneralPage() {
  const { filters } = useOutletContext<{ filters: ProjectFilters }>();
  const { hackathon } = useHackathon();

  const defaultFilters = {
    ...filters,
    flagsIdNot: !!hackathon ? [hackathon?.flagId] : undefined,
  };
  return (
    <div className="flex flex-col max-w-full mt-8 gap-8">
      <HackathonGeneral filters={filters} />
      <ProjectCarousel section="recents" filters={{ ...defaultFilters }} />
      <ProjectCarousel
        section="highlights"
        filters={{ ...defaultFilters, byUpVotes: 'more' }}
      />
      <ProjectCarousel
        section="viewed"
        filters={{ ...defaultFilters, byViews: 'more' }}
      />
    </div>
  );
}
