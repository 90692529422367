import Text from '@/components/common/dataDisplay/Text';
import Lottie from 'react-lottie';
import onGoingConstruction from '@/assets/video/lottie/ongoing-construction.json';
import MainButton from '@/components/common/buttons/MainButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@heroicons/react/outline';
export default function ProjectHackathonRankingPage() {
  const { t: tCarousel } = useTranslation('translation', {
    keyPrefix: 'projects.carousel',
  });
  const { t: tHackathon } = useTranslation('translation', {
    keyPrefix: 'hackathon',
  });
  const navigate = useNavigate();
  return (
    <div className="mt-10">
      <div className="relative flex justify-between w-full gap-2.5  items-center">
        <Text
          text={tHackathon('building')}
          format="rubik-500"
          className="text-primary"
        />

        <MainButton
          text={tCarousel('back')}
          onClick={() => {
            navigate('..');
          }}
          color="custom"
          className={'font-600 !text-14 text-primary gap-1'}
          icon={
            <ChevronLeftIcon
              className={'w-4 h-4 ease-in-out transition-all duration-200'}
            />
          }
        />
      </div>
      <Lottie
        options={{
          autoplay: true,
          loop: true,
          animationData: onGoingConstruction,
          rendererSettings: {
            className: 'w-full md:w-[80%] mx-auto',
          },
        }}
      />
    </div>
  );
}
