import { RelationEnum } from './Relationship';
import { Student } from './Student';
import UserProfile from './UserProfile';

export default class User {
  public id!: number;
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public username!: string;
  public password!: string;
  public userType!: UserTypeEnum;
  public lastLogin?: Date;
  public status!: UserStatusEnum;
  public unitsIds!: number[];
  public profile!: UserProfile;
  public timezone!: string;

  public constructor(user: Partial<User>) {
    if (user.email === undefined || user.username === undefined) {
      throw new Error('email and username are required');
    }
    Object.assign(this, user);
  }
}

export enum UserTypeEnum {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  UNIT_ADMIN = 'UNIT_ADMIN',
  PARENT = 'PARENT',
}

export enum UserStatusEnum {
  REGISTRED = 'REGISTRED',
  ACTIVE_ENROLLMENT = 'ACTIVE_ENROLLMENT',
  INACTIVE = 'INACTIVE',
  WAITING_ENROLLMENT = 'WAITING_ENROLLMENT',
}

export interface AuthToken {
  token_type: string;
  exp: number;
  jti: string;
  user_id: number;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  timezone: string;
  userType: string;
  status: string;
}

export interface AdditionalInformation {
  hasComputer: string;
  location: string;
  owner: number;
  studentAge: StudentAgeEnum;
  isActiveEmail: boolean;
}

export enum StudentAgeEnum {
  SIX_OR_LESS = 'SIX_OR_LESS',
  SEVEN_TO_NINE = 'SEVEN_TO_NINE',
  TEN_TO_THIRTEEN = 'TEN_TO_THIRTEEN',
  FOURTEEN_TO_SEVENTEEN = 'FOURTEEN_TO_SEVENTEEN',
}

export type UserForm = Omit<Student, 'profile'> &
  Omit<UserProfile, 'user' | 'dateOfBirth'> & {
    location: string;
    confirmPassword: string;
    dateOfBirth: string;
  };

export interface Contact {
  id: number;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  relation: RelationEnum | null;
}
