import { VersioningStatusEnum } from '@/enums/VersioningStatus';

export interface LastVersionDto {
  title: string;
  slug: string;
  status: VersioningStatusEnum;
  version: number;
}

export enum CourseActiveEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export class Course implements ICourse {
  id!: number;
  name!: string;
  abbreviation!: string;
  category!: CourseCategoryEnum;
  isActive!: boolean;
  bannerImg!: string | null;
  type?: string;
  url?: string;
  courseType?: CourseTypeEnum;
  lessonCount?: string | number;
  studentsCount?: number;
  duration?: number;
  coursePathSlug?: string;
  coursePathStatus?: VersioningStatusEnum;
  lastCoursePathVersion?: number;
  knowledgeOrder!: number;

  constructor(course: ICourse) {
    Object.assign(this, course);
    this.checkBannerImg();
  }

  private checkBannerImg(): void {
    if (!this.bannerImg) {
      this.bannerImg = '/images/course-default-image.png';
    }
  }
}
export enum CourseCategoryEnum {
  F2F = 'F2F',
  ONLINE = 'ONLINE',
  SCHOOL = 'SCHOOL',
}

export interface ICourse {
  id: number;
  duration?: number;
  name: string;
  abbreviation: string;
  category: CourseCategoryEnum;
  isActive: boolean;
  bannerImg: string | null;
  type?: string;
  url?: string;
  courseType?: CourseTypeEnum;
  coursePathSlug?: string;
  coursePathStatus?: VersioningStatusEnum;
  lastCoursePathVersion?: number;
  knowledgeOrder: number;
}

export interface ICoursePath {
  id: number;
  status: VersioningStatusEnum;
  version: number;
  createdAt: string;
  lastUpdate: string;
  lessonsCount: string;
  slug: string;
  course: ICourse;
  isbnCode: string | null;
  rating?: number;
}

export interface CourseStatistics {
  activeEnrollmentsCount: number;
  canceledEnrollmentsCount: number;
  reportsCount: number;
  presencesCount: number;
  previousActiveEnrollmentsCount: number;
}

export default class CoursePath implements ICoursePath {
  id!: number;
  status!: VersioningStatusEnum;
  version!: number;
  createdAt!: string;
  lastUpdate!: string;
  lessonsCount!: string;
  slug!: string;
  course!: ICourse;
  isbnCode: string | null = null;
  rating?: number;

  constructor(course: ICoursePath) {
    Object.assign(this, course);
  }
}

export enum CourseTypeEnum {
  CTRL_QUICK = 'CTRL_QUICK',
  CTRL_KIDS = 'CTRL_KIDS',
  CTRL_TEENS = 'CTRL_TEENS',
  CTRL_YOUNG = 'CTRL_YOUNG',
  CTRL_PRO = 'CTRL_PRO',
}

export enum CourseDurationEnum {
  ONE_HOUR = '1h',
  ONE_HOUR_AND_A_HALF = '1h30m',
  TWO_HOURS = '2h',
  TWO_HOURS_AND_A_HALF = '2h30m',
  THREE_HOURS = '3h',
}

export enum CourseDurationMinuteEnum {
  ONE_HOUR = 60,
  ONE_HOUR_AND_A_HALF = 90,
  TWO_HOURS = 120,
  TWO_HOURS_AND_A_HALF = 150,
  THREE_HOURS = 180,
}

export enum FrequencyTypeEnum {
  WEEKLY = 'WEEKLY',
  TWICE_WEEK = 'TWICE_WEEK',
  EVERY_WEEKDAY = 'EVERY_WEEKDAY',
}
