import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import InfinityList from '@/components/common/InfinityList';
import Card from '@/components/projects/cards/Card';
import ProjectCardsFactory from '@/components/projects/cards/factory/ProjectCardsFactory';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { ProjectFilters } from '@/data/services/projectServices';
import { projectsQuerykeys } from '@/data/services/querykeys';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import ProjectSectionHeader from './ProjectSectionHeader';
import Text from '@/components/common/dataDisplay/Text';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';

export default function ProjectSectionPage() {
  const { filters: headerFilters } = useOutletContext<{
    filters: ProjectFilters;
  }>();
  const { t: tCarousel } = useTranslation('translation', {
    keyPrefix: 'projects.carousel',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.page',
  });
  const [sectionFilters, setSectionFilters] = useState({});

  const { state } = useLocation();

  const { filters: stateFilters, section } = state;

  const allFilters = {
    ...stateFilters,
    ...headerFilters,
    ...sectionFilters,
  };

  const {
    results: projects,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteService({
    ...projectsQuerykeys.list(allFilters)._ctx.infinity,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const loadingCards = Array.from({ length: 20 }).map((_, index) => (
    <Card.Skeleton key={index} className="w-full" />
  ));
  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <div className="w-full px-2.5 py-5 sticky top-0 bg-base-200 z-10">
        <div className="absolute right-full top-0 w-5 h-full bg-base-200"></div>

        <ProjectSectionHeader
          section={section}
          filters={allFilters}
          onFilter={setSectionFilters}
          className={{
            title: 'font-600 text-20 text-accent font-poppins',
          }}
          button={
            <MainButton
              text={tCarousel('back')}
              onClick={() => {
                navigate('..');
              }}
              color="custom"
              className={twJoin('font-600 !text-14 text-primary gap-1')}
              icon={
                <ChevronLeftIcon
                  className={twJoin(
                    'w-4 h-4 ease-in-out transition-all duration-200',
                  )}
                />
              }
            />
          }
        />
        <div className="absolute left-full top-0 w-5 h-full bg-base-200"></div>
      </div>
      <ConditionalRenderer condition={!projects.length && !isLoading}>
        <div className="flex w-full justify-center items-center h-72">
          <Text text={t('noProjectsFound')} />
        </div>
      </ConditionalRenderer>
      <InfinityList
        hasNextPage={hasNextPage}
        onReachEnd={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2.5"
      >
        <ConditionalRenderer condition={!isLoading} fallback={loadingCards}>
          {projects.map(project => (
            <ProjectCardsFactory
              project={project}
              filters={allFilters}
              key={project.id}
              mode="general"
              className={{
                base: 'w-full min-w-full xl:min-w-full',
              }}
            />
          ))}
        </ConditionalRenderer>
      </InfinityList>
    </div>
  );
}
