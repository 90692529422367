import {
  AstroBreadcrumb,
  breadcrumbArray,
} from '@/components/common/AstroBreadcrumb';
import HeadTitle from '@/components/common/HeadTitle';
import PageTitle from '@/components/common/PageTitle';
import Layout from '@/components/template/Layout';
import { projectsQuerykeys } from '@/data/services/querykeys';
import { Project } from '@/models/Project';
import { ROUTES } from '@/utils/routes';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProjectService from '../../components/projects/form/ProjectService';
import LoadingView from '../courses/LoadingView';
import ErrorPage from '../notFound/ErrorPage';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

export default function ProjectFormPage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.view',
  });
  const [tPage] = useTranslation('translation', {
    keyPrefix: 'projects.page',
  });
  const { state: projectState } = useLocation();
  const { id } = useParams();
  const projectId = Number(id);
  const projectQueryKey = projectsQuerykeys.get(projectId);

  const {
    data: project,
    isInitialLoading,
    isPlaceholderData,
    error,
  } = useQuery({
    ...projectQueryKey,
    enabled: !isNaN(projectId),
    placeholderData: projectState as Project,
    retry: 1,
  });

  const isLoading = isInitialLoading || isPlaceholderData;

  const title = isNaN(projectId)
    ? tPage('addProjectTitle')
    : tPage('updateProjectTitle');
  const navigate = useNavigate();
  const breadcrumbItems: breadcrumbArray[] = [
    {
      name: 'Home',
      routeType: 'home',
      url: '/',
    },
    { name: t('projects'), routeType: 'projects', url: '/projects' },
    {
      name: title,
      routeType: 'projects',
      url: '',
    },
  ];

  if (isLoading && !project && !!projectId) {
    return <LoadingView />;
  }

  if (error && !isLoading) {
    return <ErrorPage />;
  }

  return (
    <Layout className="overflow-x-hidden">
      <HeadTitle routeInfo={`${title} - ${t('projects')}`} />
      <header className="flex flex-col gap-3">
        <AstroBreadcrumb breadcrumbList={breadcrumbItems} />
        <PageTitle position="mb-2" headingText={title} backButton />
      </header>
      <ProjectService
        project={project}
        onSuccess={data => {
          !project && navigate(ROUTES.PROJECTS.INFO(data.id));
          window.history.replaceState(null, '');
        }}
      />
    </Layout>
  );
}
