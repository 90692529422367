import { BaseModel } from '@/components/common/dataInput/SearchCheckbox';

export default interface Hackathon extends BaseModel {
  edition: number;
  title: string;
  content: string;
  flagId: number;
}

export interface HackathonStage extends BaseModel {
  hackathonId: number;
  startDate: string;
  endDate: string;
  type: StageTypeEnum;
}

export enum StageTypeEnum {
  SUBMISSION = 'SUBMISSION',
  INITIAL_VOTING = 'INITIAL_VOTING',
  FINAL_VOTING = 'FINAL_VOTING',
}
